/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from './Typography';
import Profile from './Profile';


/**
 *
 *
 *
 */
const currAppName = process.env.REACT_APP_NAME;
const displayName = currAppName.charAt(0).toUpperCase() + currAppName.slice(1).toLowerCase();


/**
 *
 *
 *
 */
Header.defaultProps = {
  $hasProfile: true
};


/**
 *
 *
 *
 */
export default function Header(props) {

  React.useEffect(() => { document.title = displayName; }, []);

  return (
    <S.Container id="header">
      <S.InnerContainer>
        <S.ItemLinkContainer href="/">
          <T.IO6>{props.name ?? displayName}</T.IO6>
        </S.ItemLinkContainer>
        <S.ItemContainer style={{ marginLeft: 'auto', paddingRight: 0 }}>
          {props.$hasProfile && <Profile />}
        </S.ItemContainer>
      </S.InnerContainer>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
var S = {};

S.Container = styled.div`
  top: 0px;
  position: fixed;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: ${p => p.theme.body};
  @media print { display: none; }
  z-index: 10;
  @media (max-width: 1220px) {
    height: 50px;
    padding: 0px 10px;
  }
`;

S.InnerContainer = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  border-bottom: rgb(237, 239, 244);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

S.ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
`;

S.ItemLinkContainer = styled.a`
  display: flex;
  align-items: center;
  padding-right: 20px;
  text-decoration: none;
  :hover { cursor: pointer; }
`;

S.Img = styled.img`
  height: 30px;
`;