/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import Cohort from '../components/Cohort';
import * as u from '../../utils';
import * as F from '../frgs';

/**
 *
 *
 *
 */
export default function Home() {

  const [ users, setUsers ] = React.useState([]);
  const cohorts = onGroupByCohort(users);
  React.useState(() => { onGetUsers(); }, []);
  const byAlpha = (a, b) => b[0].localeCompare(a[0]);

  return (
    <S.Container>
      {Object.entries(cohorts).sort(byAlpha).map(([k, v]) => (
        <Cohort key={k} group={k} image={v.image} users={v.users} />
      ))}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onGroupByCohort(users) {
    return users.reduce((acc, user) => {
      const cohortName = user?.cohort?.name;
      if (!cohortName) return acc;
      if (cohortName === 'STAFF') return acc;
      if (!acc[cohortName]) { acc[cohortName] = { ...user.cohort, users: [] }; }
      acc[cohortName].users.push(user);
      return acc;
    }, {});
  }

  /**
   *
   *
   *
   */
  async function onGetUsers() {
    const [data, err] = await u.fetcher(F.FRG_GET_USERS);
    if (err) return console.error(`onGetUsers:err`, err);
    return setUsers(data);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  margin-top: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.OptionsContainer = styled.div`
  margin-bottom: 15px;
`;
