/**
 *
 *
 *
 */
import React from 'react';
import * as d3 from 'd3';
import { DateTime } from 'luxon';


/**
 *
 *
 *
 */
const GRADS = [
  ['GREEN', 'rgb(048, 199, 088)'],
  ['RED',   'rgb(255, 059, 048)'],
  ['BLUE',  'rgb(000, 122, 255)'],
];


/**
 *
 *
 *
 */
const COLORS = {
  g: 'rgba(48, 209, 88, 1)',
  r: 'rgba(255, 59, 48, 1)',
  b: 'rgba(0, 122, 255, 1)',
};


/**
 *
 *
 *
 */
export default function Apple(props) {

  const { W, H, M } = props.opts;
  const ref = React.useRef();
  const [ isHover, setIsHover ] = React.useState(false);
  React.useEffect(onInitialise, [ref.current, props.data, isHover]);
  return (<svg ref={ref} />);

  /**
   *
   *
   *
   */
  function onInitialise() {

    if (!ref.current || !props.data || !props.data.length) return;
    const isPositive = props.data[0].y > props.data.at(-1).y;
    const [ minY, maxY ] = d3.extent(props.data, d => d.y);
    const xDomain = d3.extent(props.data.map(d => DateTime.fromISO(d.x)));
    const yDomain = [minY * 0.97, maxY * 1.03];
    const xRange = [0, W - M.R];
    const yRange = [H - M.B, M.T];

    const xTicks = 6;
    const yTicks = 4;
    const xScale = d3.scaleTime().domain(xDomain).range(xRange);
    const yScale = d3.scaleLinear().domain(yDomain).range(yRange);
    const xAxis = d3.axisBottom(xScale).ticks(xTicks);
    const yAxis = d3.axisLeft(yScale).ticks(yTicks);
    const line = d3.line().x(d => xScale(DateTime.fromISO(d.x))).y(d => yScale(d.y));
    const area = d3.area().x(d => xScale(DateTime.fromISO(d.x))).y0(H - M.B).y1(d => yScale(d.y));
    const datum = props.data.slice().sort((a, b) => DateTime.fromISO(a.x) - DateTime.fromISO(b.x));

    const bgColor = isPositive ? 'url(#GREEN)' : 'url(#RED)';
    const stColor = isPositive ? COLORS.g : COLORS.r;

    // ==============================================================================================
    // SVG
    // ==============================================================================================
    const svg = d3.select(ref.current)
      .attr('width', W)
      .attr('height', H)
      .attr('viewBox', [0, 0, W, H])
      .attr('style', 'max-width: 100%; height: auto;');

    const g = svg.append('g')
      .attr('transform', 'translate(' + M.L + ',' + M.T + ')');

    // ==============================================================================================
    // DEFS
    // ==============================================================================================
    svg.append('defs')
      .selectAll('linearGradient')
      .data(GRADS)
      .enter()
      .append('linearGradient')
      .attr('id', d => d[0])
      .attr('x1', '0')
      .attr('y1', '0')
      .attr('x2', '0')
      .attr('y2', '1')
      .selectAll('stop')
      .data(d => ([
        { offset: '0%',   stopColor: d[1], stopOpacity: '0.4', name: d[0] },
        { offset: '80%',  stopColor: d[1], stopOpacity: '0.1', name: d[0] },
        { offset: '100%', stopColor: d[1], stopOpacity: '0.1', name: d[0] },
      ]))
      .enter()
      .append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.stopColor)
      .attr('stop-opacity', d => d.stopOpacity);

    // ==============================================================================================
    // AREA
    // ==============================================================================================
    const areaG = g.append('path')
      .datum(datum)
      .attr('fill', bgColor)
      .attr('d', area);

    // ==============================================================================================
    // LINE
    // ==============================================================================================
    const lineG = g.append('path')
      .datum(datum)
      .attr('fill', 'none')
      .attr('stroke', stColor)
      .attr('stroke-width', 1.6)
      .attr('d', line);

    // ==============================================================================================
    // AXIS
    // ==============================================================================================
    g.append('g')
      .attr('color', 'black')
      .attr('transform', 'translate(0,' + (H - M.B) + ')')
      .call(xAxis)
      .selectAll('line, path')
      .attr('stroke', 'rgb(220, 220, 220)');

    g.append('g')
      .attr('color', 'black')
      .call(yAxis)
      .selectAll('line, path')
      .attr('stroke', 'rgb(220, 220, 220)');

    // ==============================================================================================
    // GRID LINES
    // ==============================================================================================
    g.append('g')
      .selectAll('line')
      .data(xScale.ticks(xTicks))
      .enter()
      .append('line')
      .attr('x1', d => xScale(d))
      .attr('x2', d => xScale(d))
      .attr('y1', 0)
      .attr('y2', H - M.B)
      .attr('fill', 'none')
      .attr('stroke-width', '0.5')
      .attr('stroke', 'rgba(16, 22, 26, .15)');

    g.append('g')
      .selectAll('line')
      .data(yScale.ticks(yTicks))
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('x2', W - M.R)
      .attr('y1', d => yScale(d))
      .attr('y2', d => yScale(d))
      .attr('fill', 'none')
      .attr('stroke-width', '0.5')
      .attr('stroke', 'rgba(16, 22, 26, .15)');

    // ==============================================================================================
    // FOX LINE & DOT
    // ==============================================================================================
    const foxLine = g.append('line')
      .style('display', 'none')
      .attr('stroke', 'rgb(0, 122, 255)')
      .attr('stroke-width', 1);

    const foxDot = g.append('circle')
      .style('display', 'none')
      .attr('r', 6)
      .attr('fill', 'rgb(0, 122, 255)')
      .attr('stroke', '#FFF')
      .attr('stroke-width', '1.5');

    // ==============================================================================================
    // EVENTS
    // ==============================================================================================
    svg.append('rect')
      .attr('width', W - M.L - 5)
      .attr('height', H - M.B)
      .attr('fill', 'rgba(167, 167, 167, 0)')
      .attr('transform', 'translate(' + M.L + ',' + M.T + ')')
      .on('mouseenter', onMouseEnter)
      .on('mousemove', onMouseMove)
      .on('mouseout', onMouseOut);

    // ==============================================================================================
    // MOUSE
    // ==============================================================================================
    function onMouseEnter() {
      foxLine.style('display', null);
      foxDot.style('display', null);
      areaG.attr('fill', 'url(#BLUE)');
      lineG.attr('stroke', COLORS.b);
    }

    function onMouseMove(evt) {
      const [ xPosPoint, _ ] = d3.pointer(evt, evt.currentTarget);
      const xPos = xScale.invert(xPosPoint + xRange[0]);
      const xTim = DateTime.fromJSDate(xPos);
      const xIdx = d3.bisector(d => DateTime.fromISO(d.x)).right(datum, xPos);
      const nexL = datum[xIdx - 1] || datum[0];
      const nexR = datum[xIdx] || datum.at(-1);
      const epsL = DateTime.fromISO(nexL.x).diff(xTim, 'seconds').seconds;
      const epsR = DateTime.fromISO(nexR.x).diff(xTim, 'seconds').seconds;
      const nexE = Math.abs(epsL) < Math.abs(epsR) ? nexL : nexR;
      const xPosNew = xScale(DateTime.fromISO(nexE.x));
      const yPosNew = yScale(nexE.y);
      foxLine.attr('x1', xPosNew).attr('x2', xPosNew).attr('y1', 0).attr('y2', H - M.B);
      foxDot.attr('cx', xPosNew).attr('cy', yPosNew);
    }

    function onMouseOut() {
      foxLine.style('display', 'none');
      foxDot.style('display', 'none');
      areaG.attr('fill', bgColor);
      lineG.attr('stroke', stColor);
    }

    return () => svg.selectAll('*').remove();
  }
}
