/**
 *
 *
 */
import { createGlobalStyle } from 'styled-components';


/**
 *
 *
 */
export const GlobeStyle = createGlobalStyle`

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    height: 0px; /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }

  body {
    padding: 0px !important;
    overflow: -moz-scrollbars-none;  /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  @font-face {
    font-family: "Virgil";
    src: url("https://excalidraw.com/Virgil.woff2");
  }

  @font-face {
    font-family: "Cascadia";
    src: url("https://excalidraw.com/Cascadia.woff2");
  }

  @font-face {
    font-family: "Assistant";
    src: url("https://excalidraw.com/Assistant-Regular.woff2");
  }
`;


/**
 *
 *
 *
 */
export const Theme = {};


/**
 *
 *
 *
 */
Theme.dark = {
  isDark: true,

  backgroundUno: 'rgba(1, 4, 9, 1)',
  backgroundDue: 'rgba(13, 17, 23, 1)',
  backgroundTre: 'rgba(22, 27, 34, 1)',
  primary: 'rgba(201, 209, 217, 1)',
  secondary: 'rgba(139, 148, 158, 1)',
  accent: 'rgba(88, 166, 255, 1)',
  accentSecondary: 'rgba(56, 139, 253, 0.15)',
  borderActive: 'rgba(247, 129, 102, 1)',
  border: 'rgba(48, 54, 61, 1)',
  btnPrimary: 'rgba(35, 134, 54, 1)',
  effectPrimary: '0px 4px 6px rgba(1, 4, 9, 0.1)',
};


/**
 *
 *
 *
 */
Theme.light = {
  isDark: false,

  primary: 'rgba(36, 41, 47, 1)',
  border: 'rgba(208, 215, 222, 1)',
  secondary: 'rgba(87, 96, 106, 1)',
  btnPrimary: 'rgba(45, 164, 78, 1)',
  accent: 'rgba(9, 105, 218, 1)',
  accentSecondary: 'rgba(221, 244, 255, 1)',
  background: 'rgba(246, 248, 250, 1)',
  borderActive: 'rgba(253, 140, 115, 1)',
  body: 'rgba(255, 255, 255, 1)',
  effectPrimary: '0px 3px 6px rgba(140, 149, 159, 0.1)',
};