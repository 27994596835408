/**
 *
 *
 *
 */
import * as d3 from 'd3';
import forge from 'node-forge';
import { faker } from '@faker-js/faker';
import { DateTime } from 'luxon';
import * as R from 'ramda';


/**
 *
 *
 *
 */
export const onHash = str => {
  var hash = 0, i, chr;
  if (!str || str.length === 0) return hash.toString();
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
};


/**
 *
 *
 *
 */
export const onParams = (str) => {
  if (!str) return {};
  const prm = str.startsWith('?') ? str.slice(1) : str;
  return prm.split('&').reduce((acc, elm) => {
    const [key, val] = elm.split('=');
    acc[key] = val;
    return acc;
  }, {});
};


/**
 *
 *
 *
 */
export const onKsbProgress = (learner, state) => {

  const byOnlyApprenticeship = elm => elm.reference === 'ST0763';
  const appKsb = R.filter(byOnlyApprenticeship, state?.ksb ?? []);
  const answersByModule = R.groupBy(R.prop('module_id'), learner?.answers ?? []);
  const modulesByKey = state?.modules?.reduce(byModule, {});
  if (!modulesByKey) return {};
  const modulesByCompleteRatio = R.mapObjIndexed(onCompleteRatio, answersByModule);
  const storeKsbByCode = R.mapObjIndexed(val => val[0], R.groupBy(R.prop('code'), appKsb ?? []));
  const getReportsWithKSB = (reports, ksb) => reports?.reduce((acc, r) => acc.concat(r?.topics?.reduce((acc, t) => {
    return (t.split('_')[1] || '').toLowerCase() === ksb.toLowerCase() ? acc.concat({ hours: Number(t.split('_')[2]), title: r.title }) : acc;
  }, []) || []), []);
  return R.mapObjIndexed(onLookUpHours, storeKsbByCode);

  /**
   *
   *
   *
   */
  function onLookUpHours(val, key) {
    const inner    = { target: val.target || 20, code: key, description: val.description };
    const onSum    = (acc, elm) => acc + elm.hours;
    inner.modules  = Object.values(modulesByCompleteRatio).filter(m => m.ksb?.includes(key));
    inner.modules  = inner.modules.map(m => ({ id: m.module_id, hours: Math.round(m.module_hours * m.percent), name: m.name }));
    inner.events   = learner?.events?.filter(e => e?.ksb?.some(k => (k.code === key) && k.reference === 'ST0763'));
    inner.events   = inner.events?.map(e => ({ id: e.id, hours: (new Date(e.ending_at) - new Date(e.starts_at)) / (1000 * 60 * 60), title: e.title }));
    inner.reports  = getReportsWithKSB(learner?.reports, key);
    inner.hours    = Math.min(inner.target, inner.modules?.reduce(onSum, 0) + inner.events?.reduce(onSum, 0) + inner.reports?.reduce(onSum, 0));
    inner.rawHours = inner.modules?.reduce(onSum, 0) + inner.events?.reduce(onSum, 0) + inner.reports?.reduce(onSum, 0);
    return inner;
  }

  /**
   *
   *
   *
   */
  function byModule(acc, mod) {
    acc[mod.id] = { ksb: mod.ksb, exercies: mod.articles?.map(a => a.exercises?.map(e => e.id)).flat(2).length, hours: mod.hours, name: mod.name };
    return acc;
  }

  /**
   *
   *
   *
   */
  function onCompleteRatio(val, key) {
    const rawModule = modulesByKey[key];
    return {
      module_id: key,
      ksb: rawModule?.ksb?.filter(byOnlyApprenticeship).map(e => e.code),
      exercies: rawModule?.exercies,
      answers: val.length,
      module_hours: rawModule?.hours,
      percent: (val.length / rawModule?.exercies || 1).toFixed(2),
      name: rawModule?.name,
    };
  }
};

/**
 *
 *
 *
 */
export const onCalculateStatusHours = (state, learner, month) => {
  const partTime = DateTime.fromISO(learner?.cohort?.ended).plus({ months: 1 }).startOf('month');
  const endMonth = DateTime.fromISO(month).endOf('month');
  const threeMonths = onHoursByMonth(learner, month).filter(e => new Date(e.date) <= new Date(month)).slice(-3);
  const hours = onHours(learner, month).filter(i => i.value > 0);
  const status = onCurrLevel(threeMonths, learner);
  return { ...learner, hours, status };

  /**
   *
   *
   *
   */
  function onCurrLevel(threeMonths, learner) {
    if (learner?.apprenticeship_status !== 'ACTIVE') return 'GOOD';
    if (!threeMonths.length) return;
    const [twoMonthAgo, oneMonthAgo, thisMonth] = threeMonths;
    if (thisMonth.hours < 1 && oneMonthAgo.hours < 1 && twoMonthAgo.hours < 1) return 'RISK';
    if (thisMonth.hours < 1 && oneMonthAgo.hours < 1) return 'PASS';
    return 'GOOD';
  }

  /**
   *
   *
   *
   */
  function onHours(elm) {
    if (!elm) return [];
    const intensive = programHours(elm);
    const otj = Math.round(otjHours(elm));
    const workshops = Math.round(workshopHours(elm));
    const platform = Math.ceil(platformHours(elm));
    const cumulative = intensive + otj + workshops + platform;
    const cumStr = cumulative > 420 ? cumulative : `${cumulative}/420`;
    const statsKsb = onKsbProgress(learner, state);
    const hrsOverTarget = Object.values(statsKsb).reduce((a, e) => ({h: a.h + e.hours, t: a.t + e.target}), {h: 0, t: 0});
    const ksbNum = (hrsOverTarget.h / hrsOverTarget.t) * 100;
    const ksbStr = ksbNum.toFixed(1) + '%';

    return [
      { key: 'cumulative', title: 'Cumulative Hours', value: cumulative, modValue: cumStr },
      { key: 'intensive', title: 'Intensive Programme', value: intensive },
      { key: 'workshops', title: 'Talks & Workshops', value: workshops },
      { key: 'platform', title: 'Online Platform', value: platform },
      { key: 'otj', title: 'Additional OTJ', value: otj },
      { key: 'ksb', title: 'KSB Progress', value: ksbNum, modValue: ksbStr },
    ];
  }

  /**
   *
   *
   *
   */
  function programHours(elm) {
    if (!elm) return 0;
    const isStathis = !elm.cohort?.start;
    if (isStathis) return 0;
    const { weeks } = DateTime.fromISO(elm.cohort?.ended).diff(DateTime.fromISO(elm.cohort?.start), 'weeks');
    return Math.ceil(weeks) * 40;
  }

  /**
   *
   *
   *
   */
  function otjHours(elm) {
    if (!elm) return 0;
    return elm.reports?.reduce((acc, rpt) => {
      const duringFullTime = DateTime.fromISO(rpt.starts_at) < partTime;
      const afterMonth = DateTime.fromISO(rpt.starts_at) > endMonth;
      if (duringFullTime || afterMonth || rpt.type !== 'TIME_RANGE' || !rpt.starts_at || !rpt.ending_at) return acc;
      const startDate = DateTime.fromISO(rpt.starts_at);
      const endDate = DateTime.fromISO(rpt.ending_at);
      let currHours = 0;
      for (let dt = startDate; dt < endDate; dt = dt.plus({ hours: 1 })) {
        if (dt.weekday >= 1 && dt.weekday <= 5) currHours++;
      }
      return acc + currHours;
    }, 0);
  }

  /**
   *
   *
   *
   */
  function workshopHours(elm) {
    if (!elm) return 0;
    return elm.events?.reduce((acc, evt) => {
      const duringFullTime = DateTime.fromISO(evt.starts_at) < partTime;
      const afterMonth = DateTime.fromISO(evt.starts_at) > endMonth;
      const noAttend = evt?.status !== 'ATTENDED';
      if (duringFullTime || afterMonth || noAttend || !evt?.ending_at || !evt?.starts_at) return acc;
      if (evt?.status === 'CANCELLED') return acc;
      const currHrs = DateTime.fromISO(evt?.ending_at).diff(DateTime.fromISO(evt?.starts_at), 'hours');
      return acc + Math.ceil(currHrs.hours);
    }, 0);
  }

  /**
   *
   *
   *
   */
  function platformHours(learner) {
    if (!learner?.stats) return 0;
    const stats = learner.stats.filter(e => DateTime.fromISO(e.ds) < endMonth);
    const eventCountRange = [3, 100];
    const minutesRange = [0.16, 4];
    const interpolateEventCountToMinutes = d3.scaleLinear().domain(eventCountRange).range(minutesRange);
    const hoursPerDay = stats.map(e => {
      const eventCount = e.num;
      const hours = interpolateEventCountToMinutes(eventCount);
      if (hours > 4) return 4;
      return hours;
    });
    const hours = hoursPerDay.reduce((acc, el ) => acc + el, 0);
    return hours;
  };
};

/**
 *
 *
 *
 */
export const onHoursByMonth = (learner, reportMonth) => {
  const endDate = DateTime.fromISO((learner?.apprenticeship_gateway)).plus({ months: 1 }).startOf('month');
  const partTimeStart = DateTime.fromISO((learner?.cohort?.ended || '2023-08-25')).plus({ months: 1 }).startOf('month');
  const res = [];
  for (let dt = partTimeStart; dt < endDate; dt = dt.plus({ month: 1 })) {
    const currentMonth = dt.toFormat('yyyy-MM');
    const hours = reportMonth < currentMonth ? 0 : hoursFrom(learner, currentMonth);
    res.push({ date: currentMonth, hours });
  }

  return res;
};

/**
 *
 *
 *
 */
export function hoursFrom(learner, month) {
  if (!learner) return 0;
  const report = reportHours(learner, month);
  const workshop = workshopHours(learner, month);
  const platform = Math.ceil(platformHours(learner, month));
  return report + workshop + platform;
}

/**
 *
 *
 *
 */
function reportHours(learner, month) {
  return learner?.reports?.reduce((acc, rpt) => {
    const startMonth = DateTime.fromISO(rpt.starts_at).toFormat('yyyy-MM');
    if (startMonth !== month || rpt.type !== 'TIME_RANGE' || !rpt.starts_at || !rpt.ending_at) return acc;
    const startDate = DateTime.fromISO(rpt.starts_at);
    const endDate = DateTime.fromISO(rpt.ending_at);
    let currHours = 0;
    for (let dt = startDate; dt < endDate; dt = dt.plus({ hours: 1 })) {
      if (dt.weekday >= 1 && dt.weekday <= 5) currHours++;
    }
    return acc + currHours;
  }, 0);
}

/**
 *
 *
 *
 */
function workshopHours(learner, month) {
  return learner?.events?.reduce((acc, evt) => {
    const startMonth = DateTime.fromISO(evt.starts_at).toFormat('yyyy-MM');
    if (startMonth !== month || !evt?.ending_at || !evt?.starts_at) return acc;
    if (evt?.status === 'CANCELLED') return acc;
    const currHrs = DateTime.fromISO(evt?.ending_at).diff(DateTime.fromISO(evt?.starts_at), 'hours');
    return acc + Math.ceil(currHrs.hours);
  }, 0);
}

/**
 *
 *
 *
 */
export const platformHours = (learner, maybeMonth) => {
  if (!learner?.stats) return 0;
  let { stats } = learner;
  if (maybeMonth) stats = learner.stats.filter(e => e.ds.includes(maybeMonth));
  const durationEstimator = initInterpolate();
  const hoursPerDay = stats.map(getHoursPerDay(durationEstimator));
  return sum(hoursPerDay);

  function initInterpolate() {
    const eventCountRange = [3, 100];
    const minutesRange = [0.16, 4];
    return d3.scaleLinear().domain(eventCountRange).range(minutesRange);
  }

  function getHoursPerDay(interpolator) {
    return (e) => {
      const eventCount = e.num;
      const hours = interpolator(eventCount);
      return Math.min(hours, 4);
    };
  }
};

/**
 *
 *
 *
 */
export const toggle = (arr=[], val) => {
  const idx = arr.indexOf(val);
  if (idx === -1) return [...arr, val];
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
};


/**
 *
 *
 *
 */
export const onShuffleGroup = (array = []) => {

  const shuffledArray = shuffle([...array]);
  const groupedArray = [];
  for (let i = 0; i < shuffledArray.length; i += 2) groupedArray.push(shuffledArray.slice(i, i + 2));
  return groupedArray;

  /**
   *
   *
   *
   */
  function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
};


/**
 *
 *
 *
 */
export const onRandom = (array) => {

  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};


/**
 *
 *
 *
 */
export const onGoogleCalendar = (params = {}) => {

  const event = {
    start: DateTime.fromISO(params?.starts_at).toFormat("yyyyMMdd'T'HHmmss"),
    end: DateTime.fromISO(params?.ending_at).toFormat("yyyyMMdd'T'HHmmss"),
    title: params.title,
    details: params.description,
  };

  if (params.online_link) event.details = `Join Meet: ${params.online_link}\n\n${event.details}`;
  if (params.address) event.location = params.address;

  const baseUrl = 'https://www.google.com/calendar/render';
  let queryString = '?action=TEMPLATE';
  queryString += '&text=' + encodeURIComponent(event.title);
  queryString += '&details=' + encodeURIComponent(event.details);
  if (params.address) queryString += '&location=' + encodeURIComponent(event.location);
  queryString += '&dates=' + encodeURIComponent(event.start) + '/' + encodeURIComponent(event.end);
  return baseUrl + queryString;
};


/**
 *
 *
 *
 */
export const getChart = () => {

  const dt = DateTime.fromISO('2023-04-01T11:12:00.000Z');
  const arr = Array.from({ length: 150 });

  return arr.map((e, i) => {
    const time = dt.plus({ minutes: i });
    const power = i < 15 ? 1 : i < 25 ? 0 : 1;
    const utils = power ? +(Math.min(Math.random() + .3, 1)) : 0;

    return [
      { time, type: 'power', value: power.toFixed(2) },
      { time, type: 'utils', value: utils.toFixed(2) },
    ];
  }).flat(1);
};


/**
 *
 *
 *
 */
export const sleep = (ms) => {
  return new Promise(done => {
    setTimeout(done, ms);
  });
};


/**
 *
 *
 *
 */
const _store = 'abcdefghijklmnopqrstuvwxyz';
export const genStr = (n=14) => [...Array(n)].map(() => _store[Math.floor(Math.random() * _store.length)]).join('');


/**
 *
 *
 *
 */
export const onGenKeys = () => {
  return new Promise((resolve, reject) => {
    forge.pki.rsa.generateKeyPair({ bits: 2048 }, async function (err, keyPair) {
      if (err) return reject(err);
      const publicKey = forge.pki.publicKeyToPem(keyPair.publicKey);
      const privateKey = forge.pki.privateKeyToPem(keyPair.privateKey);
      resolve({ publicKey, privateKey });
    });
  });
};


/**
 *
 *
 *
 */
export const onGenYearCalendar = (year, currMonth) => {

  let startDate = DateTime.fromObject({ year, month: currMonth ?? 1, day: 1 });
  let mm = DateTime.fromObject({ year, month: currMonth ?? 12 });
  let endDate = DateTime.fromObject({ year, month: currMonth ?? 12, day: mm?.daysInMonth });
  while (startDate.weekday !== 7) { startDate = startDate.minus({ days: 1 }); }
  while (endDate.weekday !== 6) { endDate = endDate.plus({ days: 1 }); }

  const dates = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const date = currentDate.toFormat('yyyy-MM-dd');
    const day = currentDate.toFormat('cccc');
    const month = currentDate.toFormat('LLL');
    const year = currentDate.toFormat('yyyy');
    dates.push({ date, day, month, year });
    currentDate = currentDate.plus({ days: 1 });
  }

  return dates;
};


/**
 *
 *
 *
 */
export const slider = (range, domain, stepSize) => {

  const scale = d3.scaleLinear()
    .domain(domain)
    .range(range);

  const valueSteps = d3.range(domain[0], domain[1], stepSize);
  const rangeSteps = valueSteps.map(scale);

  return {
    pixToSnap: (pix) => {
      const idx = d3.bisectLeft(rangeSteps, pix);
      if (idx === 0) return rangeSteps[0];
      if (idx === rangeSteps.length) return rangeSteps[rangeSteps.length - 1];
      const prev = rangeSteps[idx - 1];
      const next = rangeSteps[idx];
      return (pix - prev) < (next - pix) ? prev : next;
    },
    toRange: (val) => {
      const idx = valueSteps.indexOf(val);
      return rangeSteps[idx];
    },
    toValue: (pix) => {
      const idx = rangeSteps.indexOf(pix);
      return valueSteps[idx];
    },
  };
};


/**
 *  Example:
 *
 *  [
 *    { "name": "id", "type": "integer" },
 *    { "name": "description", "type": "text" },
 *    { "name": "name", "type": "varchar(150)" },
 *    { "name": "created_at", "type": "timestamp with time zone" }
 *  ]
 *
 */
export const onGenerateFakeData = (props) => {
  return props.reduce((acc, elm) => {

    const constraints = elm.constraints ?? {};
    const qualifiers = elm.qualifiers ?? {};
    const $length = Number(qualifiers.$length ?? 0);
    const isNotNull = !!constraints.not_null;
    const isPrimaryKey = !!constraints.primary_key;
    const maybeNull = isNotNull ? false : faker.datatype.boolean(0.2);
    const $default = constraints?.$default?.expression ?? '';
    const isSerial = $default.includes('nextval');
    if (maybeNull) { acc[elm.name] = null; return acc; }
    if (isSerial && isPrimaryKey) console.warn(`Serial & PrimaryKey`);

    switch (elm.type) {
      case 'integer':
        acc[elm.name] = faker.number.int(1000);
        return acc;
      case 'smallint':
        acc[elm.name] = faker.number.int(100);
        return acc;
      case 'text':
        acc[elm.name] = faker.lorem.paragraph();
        return acc;
      case 'varchar':
        acc[elm.name] = faker.string.alphanumeric($length);
        return acc;
      case 'character varying':
        acc[elm.name] = faker.string.alphanumeric($length);
        return acc;
      case 'jsonb':
        acc[elm.name] = JSON.stringify({ yesOrNo: faker.datatype.boolean(), foo: faker.lorem.word() });
        return acc;
      case 'timestamp with time zone':
        acc[elm.name] = faker.date.recent().toISOString();
        return acc;
      case 'boolean':
        acc[elm.name] = faker.datatype.boolean();
        return acc;
      case 'date':
        acc[elm.name] = faker.date.recent().toISOString().split('T')[0];
        return acc;
      case 'bytea':
        acc[elm.name] = btoa(faker.string.alphanumeric(10));
        return acc;
      default:
        throw new Error(`Unsupported type: ${elm.type}`);
    }
  }, {});
};


/**
 *
 *
 *
 */
export const onTraverse = (obj, callback, path = []) => {
  const maybeStop = callback(obj, path);
  if (maybeStop) return;
  if (obj === null || obj === undefined) return;
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => onTraverse(item, callback, path.concat(index)));
  } else if (typeof obj === 'object') {
    Object.keys(obj).forEach(key => onTraverse(obj[key], callback, path.concat(key)));
  }
};


/**
 *
 *
 *
 */
export const onSetObjectByPath = (path, value, obj) => {
  const createNestedObj = (path, val) => {
    if (path.length === 1) {
      const key = path[0];
      return typeof key === 'number' ? [val] : { [key]: val };
    }
    const key = path[0];
    return typeof key === 'number'
      ? [].concat(createNestedObj(path.slice(1), val))
      : { [key]: createNestedObj(path.slice(1), val) };
  };

  const nestedObj = createNestedObj(path, value);

  const isObjectVal = R.is(Object, R.path(path, obj)) && R.is(Object, value);
  return isObjectVal
    ? R.over(R.lensPath(path), R.mergeDeepRight(R.__, value), obj)
    : R.mergeDeepRight(obj, nestedObj);
};


/**
 *
 *
 *
 */
export const onTableDeepCopy = (tbl, schemaName) => {
  return Object.entries(tbl.columns).reduce((acc, [_, elm]) => {
    acc += `\t`;
    acc += (`${elm.name} - ${elm.type} ${elm.char_length ?? ''}`).trim();
    acc += `\n`;
    return acc;
  }, `Table "${schemaName}"."${tbl.name}":\n`);
};


/**
 *
 *
 *
 */
export const byName = (a, b) => {
  // Move 'id' to the top
  if (a.name === 'id') return -1;
  if (b.name === 'id') return 1;

  // Move 'updated_at' to the very bottom
  if (a.name === 'updated_at') return 1;
  if (b.name === 'updated_at') return -1;

  // Move 'created_at' toward the bottom, but above 'updated_at'
  if (a.name === 'created_at') return 1;
  if (b.name === 'created_at') return -1;

  // Move anything containing 'id' nearer to the top
  if (a.name.includes('id')) return -1;
  if (b.name.includes('id')) return 1;

  // Otherwise, maintain existing order
  return 0;
};


/**
 *
 *
 *
 */
export const debounce = (func, delay) => {

  let timer = undefined;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};


/**
 *
 *
 *
 */
export const trunc = (str, num) => {
  if (!str || str.length <= num || typeof str !== 'string') {
    return str;
  }

  const shortStr = str.slice(0, num);
  const lCommaIndex = shortStr.lastIndexOf(',');
  return num - lCommaIndex > 5 ? shortStr + '...' : str.slice(0, lCommaIndex) + '...';
};


/**
 *
 *
 *
 */
export const onTimeAgo = (time) => {
  const now = DateTime.local();
  const givenTime = (typeof time === 'number' ? DateTime.fromSeconds(time, { zone: 'utc' }) : DateTime.fromISO(time, { zone: 'utc' })).setZone('Europe/London');
  const diff = now.diff(givenTime, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']);

  if (diff.years > 0 || diff.months > 0) {
    return givenTime.toFormat('MMM dd');
  } else if (diff.days > 6) {
    return givenTime.toFormat('MMM dd');
  } else if (diff.days > 1) {
    return givenTime.toFormat('EEEE');
  } else if (diff.days === 1) {
    return 'Yesterday';
  } else if (diff.hours > 0) {
    return `${diff.hours} ${diff.hours === 1 ? 'hr' : 'hrs'} ago`;
  } else if (diff.minutes > 0) {
    return `${diff.minutes} ${diff.minutes === 1 ? 'min' : 'mins'} ago`;
  } else {
    return 'Just now';
  }
};


/**
 *
 *
 *
 */
export const fetcher = async (source, variableValues={}) => {
  try {
    const isDev = process.env.NODE_ENV === 'development';
    const currPath = isDev ? `http://api.mlx.localhost:8010` : `https://api.mlx.institute`;
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
    const opts = { method: 'POST', headers, body: JSON.stringify({ source, variableValues }) };
    opts.credentials = 'include';
    const res = await fetch(`${currPath}/g`, opts);
    if (!res.ok) throw new Error(res.status);
    const data = await res.json();
    return [data, null];
  } catch (error) {
    return [null, error];
  }
};


/**
 *
 *
 *
 */
export const removeMetadataFromVTT = (vttString) => {
  const cleanedText = vttString
    // Remove metadata headers
    .replace(/WEBVTT.*?(\r\n|\r|\n){2,}/g, '')
    // Remove timestamps and WEBVTT settings
    .replace(/^\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}.*(?:\r\n|\r|\n)/gm, '')
    // Remove lines with HTML-like tags and cues (keeping lines with just text)
    .replace(/.*<.*>.*(\r\n|\r|\n)/g, '')
    // Remove empty lines and extra whitespace
    .replace(/(^\s*$(?:\r\n|\r|\n))/gm, '')
    // Remove any trailing whitespace
    .trim();

  return [...new Set(cleanedText.split(/\r?\n/))].join('\n');
};


/**
 *
 *
 *
 */
export const onCap = str => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


/**
 *
 *
 *
 */
export const getTargetForMonth = (state, learner, month) => {
  if (learner?.target_hours) return learner?.target_hours;
  const minimumTarget = 4;
  const today = DateTime.now();
  const practicalEnd = DateTime.fromISO(learner?.apprenticeship_gateway).plus({ months: 1 });
  const timeLeft = Math.ceil(practicalEnd.diff(today, 'months').toObject().months);
  const { hours } = onCalculateStatusHours(state, learner, month);
  const total = hours.find(e => e.key === 'cumulative')?.value;
  const remainingHours = Math.max(420 - total, 0);
  const hoursPerMonth = Math.floor(remainingHours / timeLeft);
  const target = Math.max(hoursPerMonth, minimumTarget);
  return target;
};


/**
 *
 *
 *
 */
export const onApplyRuler = R.curry((ruler, str) => {
  let words = str.split(' ');
  let currLength = 0;
  let resString = '';

  for (let w of words) {
    if (currLength + w.length >= ruler) {
      resString += '\n' + w + ' ';
      currLength = w.length + 1;
    } else {
      resString += w + ' ';
      currLength += w.length + 1;
    }
  }

  return resString.trim();
});


/**
 *
 *
 *
 */
export const onGetPulseReport = (reports, tag, month) => {
  if (!reports) return null;
  const onSameMonth = e => DateTime.fromISO(e.event_time).toFormat('yyyy-MM') === month;
  const onIsOverview = e => e.topics?.includes(tag);
  const onCleanText = e => e.text?.replace(/@\w+/g, '').replace(/#\w+/g, '').trim();
  const currOverview = reports?.filter(onSameMonth).filter(onIsOverview).map(onCleanText);
  return currOverview?.[0];
};


/**
 *
 *
 *
 */
export const trimStr = (str, maxLength) => {

  if (!str) return;

  if (str.length >= maxLength) {
    return str.substring(0, maxLength) + '...';
  }

  return str;
};


/**
 *
 *
 *
 */
export const isString = (value) => {
  return typeof value === 'string';
};


/**
 *
 *
 *
 */
export function insertLineBreaks(text, maxLineLength = 70) {
  const words = text.split(' ');
  let lines = [];
  let currentLine = '';

  words.forEach(word => {
    if ((currentLine + word).length > maxLineLength) {
      lines.push(currentLine.trim());
      currentLine = word + ' ';
    } else {
      currentLine += word + ' ';
    }
  });

  if (currentLine.length > 0) {
    lines.push(currentLine.trim());
  }

  return lines.join('\n');
}


/**
 *
 *
 *
 */
function sum(arr) {
  return arr.reduce((acc, el) => acc + el, 0);
}