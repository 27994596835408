/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
export default function Cohort(props) {

  return (
    <S.Container>
      <S.InfoContainer>
        <T.IO5>{props.group}</T.IO5>
      </S.InfoContainer>
      <S.UsersContainer>
        {props.users.map(onUserElm)}
      </S.UsersContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onUserElm(user) {
    return (
      <S.UserContainer key={user.id}>
        <Router.Link to={`/${user.unix}`}>
          {(user?.photo
            ? <S.ProfileImage src={user?.photo} />
            : <T.IO5>{onGetInitials(user)}</T.IO5>
          )}
        </Router.Link>
      </S.UserContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onGetInitials(user) {
    return [
      (user?.first_name ?? [])[0] ?? '',
      (user?.last_name ?? [])[0] ?? '',
    ].join('').trim();
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  width: 100%;
  height: 175px;
  border-radius: 6px;
  border: 1px solid rgb(237, 239, 244);
  background: rgba(250, 250, 250, 0.75);
  margin-bottom: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
    height: fit-content;
  }
`;

S.InfoContainer = styled.div`
  height: 100%;
  width: 200px;
  padding: 10px;
  background: rgb(255, 255, 255);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-right: 1px solid rgb(237, 239, 244);
`;

S.UsersContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  padding: 10px;
  display: grid;
  overflow-y: scroll;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 10px;
  align-content: flex-start;
  background: rgb(237, 239, 244);
  border-radius: 0px 4px 4px 0px;
`;

S.UserContainer = styled.div`
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(208, 215, 222);
  background: rgb(255, 255, 255);
  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
`;

S.ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;